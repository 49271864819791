<template>
  <div>
    <b-card>
      <spinner-loader :loading="loading" />

      <b-tabs>
        <!-- Event -->
        <b-tab>
          <template #title>
            <span>Event</span>
          </template>

          <template v-if="!loading">
            <single-session-event-form
              v-if="event.singleSession"
              :event="event"
              :session="session"
              :disabled="true"
            />
            <event-form
              v-else
              :event.sync="event"
              :disabled="true"
            />
          </template>
        </b-tab>

        <!-- Fundraising Event -->
        <crowd-fundraising-form
          v-if="!loadingFundraising && crowdfund.id"
          :submit-functions="{}"
          :disabled="true"
          :crowdfund.sync="crowdfund"
        />
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import EventForm from '@/common/components/QuickEvents/EventForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import FundraisingApi from '@/common/compositions/Fundraising/FundraisingApi'
import CrowdFundraisingForm from '@/common/components/Fundraising/CrowdFundraising/CrowdFundraisingForm.vue'
import SingleSessionEventForm from '@/common/components/QuickEvents/SingleSessionEventForm.vue'
import Session from '@/common/compositions/QuickEvents/sessionApi'
import QuickEvent from '@/common/compositions/QuickEvents/quickEventApi'

export default {
  name: 'EditQuickEvent',
  components: {
    EventForm,
    SpinnerLoader,
    CrowdFundraisingForm,
    SingleSessionEventForm,
  },
  data() {
    return {
      loading: true,
    }
  },
  setup() {
    const {
      crowdfund, getFundraisingProject, loadingFundraising,
    } = FundraisingApi()
    const { session } = Session()
    const { getEventRequest, event } = QuickEvent()
    return {
      crowdfund,
      getFundraisingProject,
      loadingFundraising,
      session,
      getEventRequest,
      event,
    }
  },
  created() {
    this.getEvent().then(() => {
      if (this.event.fundRaisingProjectId) { this.getFundraisingProject(this.event.fundRaisingProjectId) }

      if (this.event.singleSession) {
        this.setSession()
      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    getEvent() {
      return this.getEventRequest(this.$route.params.id).then(res => {
        this.event = {
          ...res.data.data,
          location: { ...res.data.data.location },
          image: [res.data.data.image],
          brochure: [res.data.data.brochure],
        }
      })
    },
    setSession() {
      const session = this.event.sessions[0]

      this.session = {
        ...session,
        language: session.language_id,
        paidTicket: {
          paid: session.paid || 'free',
          price: session.price,
          tax_ratio: session.tax_ratio,
          charity_deduction_type: session.charity_deduction_type,
          charity_deduction_amount: session.charity_deduction_amount,
          donation_deduction_type: session.donation_deduction_type,
          donation_deduction_amount: session.donation_deduction_amount,
          payment_method: session.payment_method,
          additional_services: session.price ? session.additional_services : [],
          user_covers_comissions: session.user_covers_comissions,
          charity_id: session.charity_id,
        },
      }
    },
  },
}
</script>

<style lang="scss">
</style>
